import React from 'react';
import '../styles/mentors-cmp.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
//// [TODO]: podmienic detailsy na mentorow greenowych, teksty labelki i all ready to go
const GreenMentors = ({ openModal }) => {
  const { t } = useTranslation();

  const MENTOR_NUMBER_TO_DISPLAY = 14;
  const MENTORS_DETAILS = [
    {
      name: t('mentors.mentor1'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Tomek Konecki.jpg"
          alt="Tomek Konecki"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-green"
        />
      ),
      role: t(`mentors.mentor1-position`),
      text: t('mentors.mentor1-text'),
      description: t('mentors.mentor1-description')
    },
    {
      name: t('mentors.mentor2'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Marcin Walkowski.png"
          alt="Marcin Walkowski"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-green"
        />
      ),
      role: t(`mentors.mentor2-position`),
      text: t('mentors.mentor2-text'),
      description: t('mentors.mentor2-description')
    },
    {
      name: t('mentors.mentor5'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Ireneusz Martyniuk.jpg"
          alt="Ireneusz Martyniuk"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-green"
        />
      ),
      role: t(`mentors.mentor5-position`),
      text: t('mentors.mentor5-text'),
      description: t('mentors.mentor5-description')
    },
    {
      name: t('mentors.mentor6'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Karolina Wilk-Tryjanowska.jpg"
          alt="Karolina Wilk-Tryjanowska"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-green"
        />
      ),
      role: t(`mentors.mentor6-position`),
      text: t('mentors.mentor6-text'),
      description: t('mentors.mentor6-description')
    },
    {
      name: t('mentors.mentor7'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Olga Czerwińska.jpg"
          alt="Olga Czerwinska"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-green"
        />
      ),
      role: t(`mentors.mentor7-position`),
      text: t('mentors.mentor7-text'),
      description: t('mentors.mentor7-description')
    },
    {
      name: t('mentors.mentor8'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Jacek Bukowicki.jpg.png"
          alt="Jacek Bukowicki"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-green"
        />
      ),
      role: t(`mentors.mentor8-position`),
      text: t('mentors.mentor8-text'),
      description: t('mentors.mentor8-description')
    },
    {
      name: t('mentors.mentor9'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Anna Szymczak.jpg"
          alt="Anna Szymczak"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-green"
        />
      ),
      role: t(`mentors.mentor9-position`),
      text: t('mentors.mentor9-text'),
      description: t('mentors.mentor9-description')
    },
    {
      name: t('mentors.mentor10'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Damian Szalewicz.jpg"
          alt="Damian Szalewicz"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-green"
        />
      ),
      role: t(`mentors.mentor10-position`),
      text: t('mentors.mentor10-text'),
      description: t('mentors.mentor10-description')
    },
    {
      name: t('mentors.mentor11'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Agnieszka Musiał.png"
          alt="Agnieszka Musiał"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-green"
        />
      ),
      role: t(`mentors.mentor11-position`),
      text: t('mentors.mentor11-text'),
      description: t('mentors.mentor11-description')
    },
    {
      name: t('mentors.mentor13'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Mikołaj Gurdała.jpg"
          alt="Mikołaj Gurdała"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-green"
        />
      ),
      role: t(`mentors.mentor13-position`),
      text: t('mentors.mentor13-text'),
      description: t('mentors.mentor13-description')
    },
    {
      name: t('mentors.mentor15'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Łukasz Dziekoński.jpg"
          alt="Łukasz Dziekoński"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-green"
        />
      ),
      role: t(`mentors.mentor15-position`),
      text: t('mentors.mentor15-text'),
      description: t('mentors.mentor15-description')
    },
    {
      name: t('mentors.mentor16'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Piotr A. Pyszkowski.jpg"
          alt="Piotr A. Pyszkowski"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-green"
        />
      ),
      role: t(`mentors.mentor16-position`),
      text: t('mentors.mentor16-text'),
      description: t('mentors.mentor16-description')
    },
    {
      name: t('mentors.mentor19'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Bartłomiej Postek.jpeg"
          alt="Bartłomiej Postek"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-green"
        />
      ),
      role: t(`mentors.mentor19-position`),
      text: t('mentors.mentor19-text'),
      description: t('mentors.mentor19-description')
    },
    {
      name: t('mentors.mentor20'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Jan Jakub Cendrowski.png"
          alt="Jan Jakub Cendrowski"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-green"
        />
      ),
      role: t(`mentors.mentor20-position`),
      text: t('mentors.mentor20-text'),
      description: t('mentors.mentor20-description')
    }
  ];

  return (
    <div className="mentors-ctn">
      <div className="logo-cmp">
        <StaticImage
          src="../../../images/logos/inhub/gic_dark.png"
          alt="Logo MedBiz Innovations Program"
          placeholder="none"
          loading="eager"
          quality={100}
          className="initiative-logo"
        />
      </div>
      <div className="mentors-grid green-dark">
        {MENTORS_DETAILS.slice(0, MENTOR_NUMBER_TO_DISPLAY).map((mentor) => (
          <div
            className="shade-on-hover"
            key={mentor.name}
            role="button"
            tabIndex={0}
            onClick={() => openModal(mentor)}
            onKeyDown={() => openModal(mentor)}>
            <div className="mask-border">
              <div className="mask-fill-green">
                <p>
                  <b>{t`mentors.read-more`}</b>
                </p>
              </div>
              {mentor.avatar}
            </div>
            <h3 className="mb-1rem">{mentor.name}</h3>
            <h4>{mentor.role}</h4>
            <p>{mentor.text}</p>
            <div className="read-more-mentors">
              <div className="green-dark">
                <p>{t`mentors.read-more`}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GreenMentors;
