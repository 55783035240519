import React from 'react';
import styled from 'styled-components';
import { Column, Row } from './utils';
const TabButton = styled.div`
  margin-right: 1rem;
  padding: 1rem 2rem;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  border-radius: 10px 10px 0 0;
  font-size: 14px;
  font-weight: 800;
  box-sizing: border-box;
  display: block;
  text-align: center;
  min-width: 120px;
  transition: 0.2s ease-out;

  &.active {
    background-color: var(--inhub-dark-blue) !important;
    border: 2px solid var(--inhub-dark-blue) !important;
    color: var(--inhub-white) !important;
  }
  &.green {
    background-color: transparent;
    border-top: 2px solid var(--inhub-dark-blue);
    border-right: 2px solid var(--inhub-dark-blue);
    border-left: 2px solid var(--inhub-dark-blue);
    color: var(--inhub-dark-blue);
  }
  &.blue {
    background-color: transparent;
    border-top: 2px solid var(--inhub-dark-blue);
    border-right: 2px solid var(--inhub-dark-blue);
    border-left: 2px solid var(--inhub-dark-blue);
    color: var(--inhub-dark-blue);
  }
  &.blue :hover {
    background-color: var(--inhub-dark-blue) !important;
    border: 2px solid var(--inhub-dark-blue) !important;
    color: var(--inhub-white) !important;
  }
  &.green :hover {
    background-color: var(--inhub-dark-blue) !important;
    border: 2px solid var(--inhub-dark-blue) !important;
    color: var(--inhub-white) !important;
  }
`;

const Tabs = ({ children, buttons, tabChanged }) => {
  if (!Array.isArray(children) || !Array.isArray(buttons))
    throw 'Children or labels must be array!';

  const [selectedTab, setSelectedTab] = React.useState(0);

  const changeTab = (tab) => {
    setSelectedTab(tab);
    tabChanged(tab);
  };

  return (
    <Column>
      <Row>
        {buttons.map(({ label, className }, index) => (
          <TabButton
            className={`${selectedTab === index ? 'active' : ''} ${
              className ? className : ''
            }`}
            key={label}
            onClick={() => changeTab(index)}>
            {label}
          </TabButton>
        ))}
      </Row>
      {children[selectedTab]}
    </Column>
  );
};

export default Tabs;
