import React from 'react';
import './mentors.css';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_MBIP.jpg';
import Layout from '../../components/layout/layout';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import RegisterNow from "./components/register-now";
import MentorsCmp from "./components/mentors-cmp";

const Mentors = () => {
  const {t} = useTranslation();

  return (
    <Layout withHeader={false}>

      <Seo
        title={t`mentors.page-title`}
        description={t`mentors.page-description`}
        ogImage={ogImage}
      />

      <div className="mentors-ctn">
        <MentorsCmp/>
        <RegisterNow/>
      </div>
    </Layout>
  );
}

export default Mentors;
