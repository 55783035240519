import React from 'react';
import '../styles/mentors-cmp.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const MedbizMentors = ({ openModal }) => {
  const { t } = useTranslation();

  const MENTOR_NUMBER_TO_DISPLAY = 18;
  const MENTORS_DETAILS = [
    {
      name: t('mentors.mentor1'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Tomek Konecki.jpg"
          alt="Tomek Konecki"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor1-position`),
      text: t('mentors.mentor1-text'),
      description: t('mentors.mentor1-description')
    },
    {
      name: t('mentors.mentor2'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Marcin Walkowski.png"
          alt="Marcin Walkowski"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor2-position`),
      text: t('mentors.mentor2-text'),
      description: t('mentors.mentor2-description')
    },
    {
      name: t('mentors.mentor3'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Ligia Kornowska.jpg"
          alt="Ligia Kornowska"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor3-position`),
      text: t('mentors.mentor3-text'),
      description: t('mentors.mentor3-description')
    },
    {
      name: t('mentors.mentor4'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Karolina Kornowska (1).jpg"
          alt="Karolina Kornowska"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor4-position`),
      text: t('mentors.mentor4-text'),
      description: t('mentors.mentor4-description')
    },
    {
      name: t('mentors.mentor5'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Ireneusz Martyniuk.jpg"
          alt="Ireneusz Martyniuk"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor5-position`),
      text: t('mentors.mentor5-text'),
      description: t('mentors.mentor5-description')
    },
    {
      name: t('mentors.mentor6'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Karolina Wilk-Tryjanowska.jpg"
          alt="Karolina Wilk-Tryjanowska"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor6-position`),
      text: t('mentors.mentor6-text'),
      description: t('mentors.mentor6-description')
    },
    {
      name: t('mentors.mentor7'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Olga Czerwińska.jpg"
          alt="Olga Czerwinska"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor7-position`),
      text: t('mentors.mentor7-text'),
      description: t('mentors.mentor7-description')
    },
    {
      name: t('mentors.mentor8'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Jacek Bukowicki.jpg.png"
          alt="Jacek Bukowicki"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor8-position`),
      text: t('mentors.mentor8-text'),
      description: t('mentors.mentor8-description')
    },
    {
      name: t('mentors.mentor9'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Anna Szymczak.jpg"
          alt="Anna Szymczak"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor9-position`),
      text: t('mentors.mentor9-text'),
      description: t('mentors.mentor9-description')
    },
    {
      name: t('mentors.mentor10'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Damian Szalewicz.jpg"
          alt="Damian Szalewicz"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor10-position`),
      text: t('mentors.mentor10-text'),
      description: t('mentors.mentor10-description')
    },
    {
      name: t(`mentors.mentor12`),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Przemysław Grzywa.jpg"
          alt="Przemysław Grzywa"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor12-position`),
      text: t('mentors.mentor12-text'),
      description: t('mentors.mentor12-description')
    },
    {
      name: t('mentors.mentor13'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Mikołaj Gurdała.jpg"
          alt="Mikołaj Gurdała"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor13-position`),
      text: t('mentors.mentor13-text'),
      description: t('mentors.mentor13-description')
    },
    {
      name: t('mentors.mentor14'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Małgorzata Gratys.jpeg"
          alt="Małgorzata Gratys"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor14-position`),
      text: t('mentors.mentor14-text'),
      description: t('mentors.mentor14-description')
    },
    {
      name: t('mentors.mentor16'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Piotr A. Pyszkowski.jpg"
          alt="Piotr A. Pyszkowski"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor16-position`),
      text: t('mentors.mentor16-text'),
      description: t('mentors.mentor16-description')
    },
    {
      name: t('mentors.mentor17'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Krzysztof Czuba.jpg"
          alt="Krzysztof Czuba"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor17-position`),
      text: t('mentors.mentor17-text'),
      description: t('mentors.mentor17-description')
    },
    {
      name: t('mentors.mentor18'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Karolina Klag.jpg"
          alt="Karolina Klag"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor18-position`),
      text: t('mentors.mentor18-text'),
      description: t('mentors.mentor18-description')
    },
    {
      name: t('mentors.mentor19'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Bartłomiej Postek.jpeg"
          alt="Bartłomiej Postek"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor19-position`),
      text: t('mentors.mentor19-text'),
      description: t('mentors.mentor19-description')
    },
    {
      name: t('mentors.mentor21'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/karolina-tkaczuk.jpg"
          alt="Karolina Tkaczuk"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-square mentor-img"
        />
      ),
      role: t(`mentors.mentor21-position`),
      text: t('mentors.mentor21-text'),
      description: t('mentors.mentor21-description')
    }
  ];

  return (
    <div className="mentors-ctn">
      <div className="logo-cmp">
        <StaticImage
          src="../../../images/logos/inhub/medbiz_dark.png"
          alt="Logo MedBiz Innovations Program"
          placeholder="none"
          loading="eager"
          quality={100}
          className="initiative-logo"
        />
      </div>
      <div className="mentors-grid">
        {MENTORS_DETAILS.slice(0, MENTOR_NUMBER_TO_DISPLAY).map((mentor) => (
          <div
            className="shade-on-hover"
            role="button"
            key={mentor.name}
            tabIndex={0}
            onClick={() => openModal(mentor)}
            onKeyDown={() => openModal(mentor)}>
            <div className="mask-border">
              <div className="mask-fill">
                <p>
                  <b>{t`mentors.read-more`}</b>
                </p>
              </div>
              {mentor.avatar}
            </div>
            <h3 className="mb-1rem">{mentor.name}</h3>
            <h4>{mentor.role}</h4>
            <p>{mentor.text}</p>
            <div className="read-more-mentors">
              <p>{t`mentors.read-more`}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedbizMentors;
