import React, { useState } from 'react';
import '../styles/mentors-cmp.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import closeIcon from '../../../images/general/close-icon.svg';
import Modal from 'react-modal';
import '../styles/mentors-popup-styles.css';
import MedbizMentors from './medbiz-mentors';
import Tabs from '../../../components/global/tabs';
import GreenMentors from './green-mentors';

const boxStyles = {
  overlay: {
    zIndex: 103
  },
  content: {
    margin: 'auto',
    inset: '0',
    width: '80%',
    maxHeight: '50vh',
    maxWidth: '100rem'
  }
};

const MentorsCmp = () => {
  const { t } = useTranslation();

  const MENTORS_DETAILS = [
    {
      name: t('mentors.mentor12'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/dawid-zielinski.jpg"
          alt="Dawid Zieliński"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor12-position`),
      description: t('mentors.mentor12-description')
    },
    {
      name: t('mentors.mentor2'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/jerzy-szewczyk.jpg"
          alt="Jerzy Szewczyk"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor2-position`),
      description: t('mentors.mentor2-description')
    },
    {
      name: t('mentors.mentor3'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/piotr-pyszkowski.jpg"
          alt="Piotr A. Pyszkowski"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor3-position`),
      description: t('mentors.mentor3-description')
    },
    {
      name: t('mentors.mentor4'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/olga-czerwinska.jpg"
          alt="Olga Czerwińska"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor4-position`),
      description: t('mentors.mentor4-description')
    },
    {
      name: t('mentors.mentor5'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/kamil-wiecek.jpg"
          alt="Kamil Więcek"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor5-position`),
      description: t('mentors.mentor5-description')
    },
    {
      name: t('mentors.mentor6'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/giancarlo-la-pietra.jpg"
          alt="Giancarlo La Pietra"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor6-position`),
      description: t('mentors.mentor6-description')
    },
    {
      name: t('mentors.mentor7'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/rajmund-martyniuk.jpg"
          alt="Ireneusz Martyniuk"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor7-position`),
      description: t('mentors.mentor7-description')
    },
    {
      name: t('mentors.mentor8'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/piotr-mirosz.jpg"
          alt="Piotr Mirosz"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor8-position`),
      description: t('mentors.mentor8-description')
    },
    {
      name: t('mentors.mentor9'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/ligia-kornowska.jpg"
          alt="Ligia Kornowska"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor9-position`),
      description: t('mentors.mentor9-description')
    },
    {
      name: t('mentors.mentor10'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/arkadiusz-cempura.jpg"
          alt="Arkadiusz Cempura"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor10-position`),
      description: t('mentors.mentor10-description')
    },
    {
      name: t('mentors.mentor11'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/robert-stachurski.jpg"
          alt="Robert Stachurski"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor11-position`),
      description: t('mentors.mentor11-description')
    },
    {
      name: t(`mentors.mentor1`),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/artur-chajewski.jpg"
          alt="Artur Chajewski"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor1-position`),
      description: t('mentors.mentor1-description')
    },
    {
      name: t('mentors.mentor13'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/grzegorz-kaszynski.jpg"
          alt="Grzegorz Kaszyński"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor13-position`),
      description: t('mentors.mentor13-description')
    },
    {
      name: t('mentors.mentor14'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/christopher-kaplan.jpg"
          alt="Christopher Kaplan"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-dark-blue"
        />
      ),
      role: t(`mentors.mentor14-position`),
      description: t('mentors.mentor14-description')
    },
    {
      name: t('mentors.mentor15'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/jerzy-szewczyk.jpg"
          alt="Jerzy Szewczyk"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor15-position`),
      description: t('mentors.mentor15-description')
    }
  ];

  const TAB_BUTTONS = [
    {
      label: 'MedBiz Innovations Program', // dodac tlumaczenia
      className: 'blue'
    },
    { label: 'Green Innovations Challenge', className: 'green' }
  ];

  const [isModal, setIsModal] = React.useState(false);
  const [currentMentor, setMentor] = React.useState(MENTORS_DETAILS[0]);
  const [isMedBiz, setIsMedBiz] = useState(true);

  const openModal = (mentor) => {
    setMentor(mentor);
    setIsModal(true);
  };

  return (
    <>
      <Modal
        isOpen={isModal}
        style={boxStyles}
        onRequestClose={() => setIsModal(false)}
        shouldCloseOnOverlayClick={true}>
        {isMedBiz && (
          <>
            <div className="circles">
              <StaticImage
                className="circle-motive-left-mentors-details"
                src="../../../images/mentoring/circles10.png"
                alt="Circle motive left-up"
                placeholder="none"
                loading="lazy"
              />
              <StaticImage
                className="circle-motive-right-mentors-details"
                src="../../../images/mentoring/circles11.png"
                alt="Circle motive right"
                placeholder="none"
                loading="lazy"
              />
              <div className="mentors-ctn">
                <div className="modal-ctn">
                  <div className="btn-close-box">
                    <button onClick={() => setIsModal(false)}>
                      <img src={closeIcon} alt="close icon" />
                    </button>
                  </div>
                  <div className="container modal-text-avatar-ctn">
                    <div className="mask-border">
                      <div className="mask-fill">{currentMentor.avatar}</div>
                    </div>
                    <div className="text-ctn">
                      <h2>{currentMentor.name}</h2>
                      <p className="mentor-role">{currentMentor.role}</p>
                      <p className="description">{currentMentor.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!isMedBiz && (
          <>
            <div className="circles">
              <StaticImage
                className="circle-motive-left-mentors-details"
                src="../../../images/green-innovations-challenge/theme/leaf3.png"
                alt="Leaf"
                placeholder="none"
                loading="lazy"
              />
              <StaticImage
                className="circle-motive-right-mentors-details"
                src="../../../images/green-innovations-challenge/theme/leaf4.png"
                alt="Leaf"
                placeholder="none"
                loading="lazy"
              />
              <div className="mentors-ctn">
                <div className="modal-ctn">
                  <div className="btn-close-box">
                    <button onClick={() => setIsModal(false)}>
                      <img src={closeIcon} alt="close icon" />
                    </button>
                  </div>
                  <div className="container modal-text-avatar-ctn">
                    <div className="mask-border">
                      <div className="mask-fill">{currentMentor.avatar}</div>
                    </div>
                    <div className="text-ctn">
                      <div className="green-dark">
                        <h2>{currentMentor.name}</h2>
                      </div>
                      <p className="mentor-role green-light">
                        {currentMentor.role}
                      </p>
                      <p className="description">{currentMentor.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
      <div className="circles">
        <StaticImage
          className="circle-motive-left-up-mentors"
          src="../../../images/mentoring/circles4.png"
          alt="Circle motive left-up"
          placeholder="none"
          loading="lazy"
        />
        <StaticImage
          className="circle-motive-right-mentors"
          src="../../../images/mentoring/circles8.png"
          alt="Circle motive right"
          placeholder="none"
          loading="lazy"
        />
        <StaticImage
          className="circle-motive-left-down-mentors"
          src="../../../images/mentoring/circles10.png"
          alt="Circle motive left-down"
          placeholder="none"
          loading="lazy"
        />
        <div className="container">
          <div className="top-ctn">
            <h2 className="mentors-title">{t`mentors.mentors-title`}</h2>
            <p className="mentors-text">{t`mentors.mentors-text`}</p>
          </div>
          <Tabs
            buttons={TAB_BUTTONS}
            tabChanged={(index) => setIsMedBiz(index === 0)}>
            <MedbizMentors openModal={(mentor) => openModal(mentor)} />
            <GreenMentors openModal={(mentor) => openModal(mentor)} />
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default MentorsCmp;
