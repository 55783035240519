import React from 'react';
import '../styles/register-now.css'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {FullBtn} from "./mentors-buttons";

const RegisterNow = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="rn-ctn">
        <div className="rn-text-ctn container">
          <h3>{t`mentors.title-rn`}</h3>
        </div>
        <div className="register-now-btn">
          <FullBtn to="/registration">
            {t`mentors.register-now`}
          </FullBtn>
        </div>
      </div>
    </>
  );
}

export default RegisterNow
